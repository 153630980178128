import React from 'react'
import Layout from '../../components/layout'
import Seo from "../../components/seo"
import PageHead from "../../components/PageHead"
import CodeViewer from '../../components/CodeViewer'
import { Container, Row, Col } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
export default function MaskAI() {
    let oneHotEncoding = `imageList = []

    exclude = ['Mouth','Nose', 'Chin']
    for filename in arr:
      if filename.endswith('.jpg'):
        nose  = 0 if 'Nose'  in filename else 1
        mouth = 0 if 'Mouth' in filename else 1
        chin  = 0 if 'Chin'  in filename else 1
      
        if any([value in filename for value in exclude]):
          imageList.append([filename, nose, mouth, chin, 0])
        else:
          imageList.append([filename, 0, 0, 0, 1])
    
    df = DataFrame(data= imageList, columns = ['imageId', 'nose', 'mouth', 'chin', 'fullMask'])
    df.head()`
    let numpyArray = `path = './maskPictures/'
    imageSize = 250
    imageDataSet = []
    # convert to numpy array
    for i in range(df.shape[0]):
      img = load_img(path + df['imageId'][i], target_size=(imageSize,imageSize,3))
      img = img_to_array(img)
      img = img/255.
      imageDataSet.append(img)

    x = np.array(imageDataSet)
    x[0].shape

    y = np.array(df.drop(columns=['imageId'], axis=1))
    x_train, x_test, y_train, y_test = train_test_split(x, y, test_size=0.2, random_state=20) 

    `
    let modelCode = `checkpointer=ModelCheckpoint(filepath='./best_weights1.hdf5',verbose=0,save_best_only=True)

    model = Sequential()
    
    model.add(Conv2D(filters=16, kernel_size=(5, 5), activation="relu", input_shape=(imageSize, imageSize,3)))
    model.add(MaxPooling2D(pool_size=(2, 2)))
    model.add(Dropout(0.2))
    
    model.add(Conv2D(filters=32, kernel_size=(5, 5), activation='relu'))
    model.add(MaxPooling2D(pool_size=(2, 2)))
    model.add(Dropout(0.2))
    
    model.add(Conv2D(filters=64, kernel_size=(5, 5), activation="relu"))
    model.add(MaxPooling2D(pool_size=(2, 2)))
    model.add(Dropout(0.2))
    
    model.add(Conv2D(filters=64, kernel_size=(5, 5), activation="relu"))
    model.add(MaxPooling2D(pool_size=(2, 2)))
    model.add(Dropout(0.2))
    
    model.add(Flatten())
    model.add(Dense(128, activation='relu'))
    model.add(Dropout(0.5))
    model.add(Dense(64, activation='relu'))
    model.add(Dropout(0.5))
    model.add(Dense(4, activation='sigmoid'))
    
    model.compile(optimizer='adam', loss='binary_crossentropy', metrics=['accuracy'])
    
    monitor=EarlyStopping(monitor='val_loss',min_delta=1e-3,patience=3,verbose=2,mode='auto')
    
    history = model.fit(x_train, y_train, epochs=100, validation_data=(x_test, y_test), callbacks=[checkpointer,monitor], batch_size=128)
    `
    return (
        <Layout >
            <Seo title="Mask AI" description="Convolutional Neural Network for analysing how people wear masks." />
            <PageHead title={"Mask AI"} description={"Convolutional Neural Network for analysing how people wear masks."} />
            <Container fluid>
                <Row className='center'>
                    <Col md={6} className=''>
                        <StaticImage src='../../images/maskApp1.png' loading='lazy' />

                    </Col>

                    <Col md={6} className='fs-5'>
                        <h2>Why?</h2>
                        <p > Wearing a face mask properly is one of the many steps to help reduce the
                            spread of COVID-19. I wanted to use AI to create a tool that can analyze the way people wear masks.
                        </p></Col>
                </Row>
                <div style={{ 'textAlign': 'left' }}>
                    <h2 className=' mt-5'>Dataset</h2>

                    <p className='fs-5'> The dataset includes images of people with masks that are correctly and incorrectly worn.
                        The images are 1024 x 1024 in resolution and the image names give us the information about the
                        mask. Example: 01004_Mask_Mouth_Chin.jpg. Link to the data <a href="https://github.com/cabani/MaskedFace-Net" target="_blank">Github</a>

                    </p>
                    <p className=' mt-5 fs-5'> Using the information in the image name, we can one-hot encode the
                        values for nose, mouth, chin, and full mask.
                    </p>

                    <CodeViewer code={oneHotEncoding} />
                    <h2 className=' mt-5'>Next, we turn the images to numpy arrays.</h2>

                    <p className='fs-5'> Turn the images into numpy arrays. The x is the image data
                        and the y is the training data.
                    </p>

                    <CodeViewer code={numpyArray} />

                    <h2 className=' mt-5'>Convolutional Neural Network</h2>

                    <p className='fs-5'> The input shape is the shape of the image(250x250). We use convolutional layers to extract features.
                    In the output layer, it is important that we use the sigmoid function because we are working with a multi-label classification problem 
                    and we want probabilities that are independent.
                    </p>

                    <CodeViewer code={modelCode}/>

                    <h2 className=' mt-5'>Results</h2>
                    <p className='fs-5'> 
                    </p>
                    <StaticImage src='../../images/maskAIresults.jpg' loading='lazy' />
                    <p className='fs-5  mt-5'> Sample predictions</p>

                    <StaticImage src='../../images/maskApp1.png' loading='lazy' />

                </div>

            </Container>



        </Layout>
    )
}
