import React, { useState } from 'react';
import AceEditor from "react-ace";
import Seo from "./seo"
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-nord_dark";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap'

export default function CodeViewer({ code }) {


    return (

        <>
            <Seo title="Projects" />
            <Container fluid  >
                <div className='row justify-content-center'>

                    <AceEditor
                        className='col'
                        mode='python'
                        fontSize='15px'
                        value={code}
                        maxLines={15}
                        minLines={5}
                        readOnly
                        theme='nord_dark'
                        
                    />
                </div>


            </Container>
        </>
    )
}
